import { Footer, Header } from 'Layouts';
import styled from 'styled-components';


const Wrapper = styled.div``;

function MainLayout({ children }) {

	return (
		<Wrapper className="">
			<Header />
			{children}
			<Footer />
		</Wrapper>
	);
}

export default MainLayout;
