import { Description, ImageLogo, WrapFooter, WrapRow } from "./styled";
import React from "react";
import { LOGO_FOOTER } from "Assets/Image";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t] = useTranslation('common');
  return (
    <WrapFooter>
      <WrapRow justify={"center"} align={"middle"} >
        <Description>
            {"Copyright © Sanaru Co.Ltd.,All Rights Reserved".toUpperCase()}
        </Description>
        <Description>
            {t("do_not_coppyright_message")}
        </Description>
      </WrapRow>
			<WrapRow justify={"center"} align={"middle"} >
        <ImageLogo src={LOGO_FOOTER}/>
      </WrapRow>
    </WrapFooter>
  );
}

export default Footer;
