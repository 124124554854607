import { Layout } from 'antd';
import styled from 'styled-components';

export const WrapHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #7D0006 !important;
  height: 6.6rem;
  text-align: "center";
  padding-inline: 5rem 20px !important;
  @media only screen and (max-width: 1280px) {
    padding-inline: 10px !important;
  } 
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  } 
`;

export const Description = styled.span`
  font-weight: 800;
  font-size: 2.6rem;
  line-height: 3.1rem;
  color: #FFFFFF;
  white-space: nowrap;
  cursor: pointer;

  &.reset-form{
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  } 
`;

export const NotiMaintenance = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4rem;
  color: #000;
  background-color: #f7e7c3;
  padding: 4px 20px;
  min-width: 500px; 
  max-width: 900px;
  word-break: break-all;
  border: 1px solid #ff8400;
  border-bottom: 4px solid #ff8400;
  border-radius: 4px;
  white-space: pre-wrap;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    svg{
    margin-right: 10px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  } 
  @media only screen and (max-width: 992px) {
    font-size: 10px;
  } 
`
