import styled from "styled-components";

export const WrapAdmin = styled.div`
  height: 100vh;
  overflow: hidden;
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 7px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: #c4c4c4;
  }
  .logo {
    height: 64px;
    display: flex;
    justify-content: end;
    .resize-sidebar {
      color: white;
      svg {
        font-size: 20px;
      }
      &:hover {
        color: white;
      }
    }
  }
  .ant-layout-header {
    display: flex;
    align-items: center;
    .ant-btn {
      font-size: 16px;
      width: 64px;
      height: 64px;
    }
  }
  .ant-layout-content {
    overflow-y: auto;
    height: 100vh;
    margin: 24px 16px;
    padding: 10px 24px 0 24px ;
    min-height: 280px;
    padding-bottom: 100px;
    background-color: rgb(255, 255, 255);
  }
  .ant-layout-sider.ant-layout-sider-dark {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
  .ant-layout-sider-children {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
  .ant-menu-title-content {
    a {
      text-decoration: none;
    }
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #001529;
  }
  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 0px;

    .ant-select {
      min-height: 40px !important;
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      .select__group-school {
        min-height: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
        
      }
    }
    .ant-select-clear{
      display: flex !important;
    }

    .ant-form-item-row {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: start;
    }

    .ant-select-disabled {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === "error"
          ? `1px solid ${theme.error_ant} !important`
          : ""};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === "error" ? "none" : ""};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after {
      content: "" !important;
    }
    .ant-col.ant-form-item-label label{
      white-space: nowrap;
    }

  }
  .ant-table-expanded-row-fixed{
    font-size:14px;
    padding:0;
    margin:0;
  }
  .ant-layout-sider-collapsed {
    .logout{
      font-size:10px;
    }
  }
  .table__flow--import.ant-table-wrapper {
    table{
      border-collapse: collapse;
      /* border: 1px solid black; */
      th{
        border:none;
        border-left: 2px solid black;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        border-right: 2px solid black;

        &.step-import {
          border-bottom: 2px solid black;
          :last-child{
            border-right: 2px solid black;
          }
        }
        &.data-step{
          :last-child{
            border-right: 2px solid black;
          }
        }
      }
      td{
        /* border: 0.1px solid black; */
        &.data-step{
          border-right: 2px solid black;
          :first-child{
           border-left: 2px solid black;
          }
          :last-child{
            border-right: 2px solid black;
          }
        }
      }
    }
    .ant-table-container table>thead>tr:first-child >*:first-child {
      border-start-start-radius: unset;
    }
  }
  .ant-layout-sider-collapsed .logout-tab {
    margin-left:0;
    justify-content:center;
  }
  label {
    color:#0b0c0c !important;
    font-weight:bold;
    div{
      color:#0b0c0c !important;
    font-weight:bold;

    }
  }
  .ant-form-item-control-input{
    .ant-select-selector {
      border: 1px solid #8f9595;
    }
  }
  .ant-picker, .form-control {
    border: 1px solid #8f9595;
  }
  .ant-input::placeholder {
    color: #00000040; 
  }
  .ant-input-affix-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .ant-input {
      font-size:16px;
    }
    .ant-input-clear-icon{
      display: flex;
    }
  }
  .error_import{
      color: #f14a4a;
    }
`;
export const TitlePage = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
  margin-right: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
export const ActionHeader = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-right: 20px;
  justify-content: end;
  gap: 0 15px;
`;
export const ActionButton = styled.button`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  border: none;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    height: 30px;
    line-height: 10px;
  }
`;

export const Logout = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 28px;

`;
export const LogoutAction = styled.div`
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  .anticon {
    margin-right: 6px;
  }
`;
export const WrapperLabel = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 1rem;
  color: #6d737a;
`;
export const SelectItem = styled.div`
  max-width: 200px;
  width: 100%;
  margin-left: 20px;
`;
export const TableContainer = styled.div`
  margin-top: 24px;
  .ant-table-wrapper {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    overflow-x: auto;
    .ant-table .ant-table-cell {
      text-align: center;
    }
    table {
      border-collapse: collapse;
      th {
        vertical-align: middle;
        /* background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important; */
        background: #394983;
        color: white;
        ::before{
          display: none;
        }
        &.data__edited{
          min-width: 120px;
        }
        
      }
      td,
      th {
        font-size: 12px;
        border: 1px solid #f0f0f0;
        min-width: 100px;
        padding: 10px 4px;
        &.min-150{
          min-width: 150px;
        }
        &.min-170{
          min-width: 170px;
        }
      }
      tr {
        border-bottom: 1px solid #f0f0f0;
      }
      .line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      .course__name {
        display: -webkit-box;
        width: max-content;
      }
      .id_record{
        text-decoration: underline;
        cursor: pointer;
        color: blue;
      }
    }
    /* .ant-table-content {
      overflow-x: auto;
    }
    .ant-pagination {
      padding: 0 16px;
    } */
  }
  .ant-table-body{
    overflow: auto auto !important;
    .ant-table-placeholder{
      .ant-table-cell {
        padding: 0;
      }
    }
    .status-import {
      cursor: pointer;
      padding: 0;
    }
  }
`;
export const ErrorField = styled.div`
  position: relative;
`
export const ErrorMessage = styled.div`
  position: absolute;
  top: 6px;
  font-size: 10px;
  color: red;
  width: max-content;
`

export const HeaderTitle = styled.div`
  width: 100%;
  margin: 0 24px;
  font-size: 24px;
  font-weight: 600;
  span {
    padding: 0 6px;
    border-left: 4px solid #000;
  }
`