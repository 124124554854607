import React, { useEffect } from "react";
import { Description, NotiMaintenance, WrapHeader,  } from './styled';
import { Layout } from 'antd';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import actions from "Store/Actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { SHOW_NOTI } from "Utils/data-default";
import { WarningOutlined } from "@ant-design/icons";
const {
	getInfoMaintenance,
	resetAllInfomation
  } = actions;
function Header() {
	const dispatch = useDispatch();
	const location = useLocation()
	const history = useHistory();
	const [t] = useTranslation('common');
	const {
		info_maintenance_student,
		studentID
	} = useSelector((state) => state.student);

	const handleResetForm = () => {
		if (location.pathname.includes('/student/curriculum') && location.pathname.includes('/create')) {
		  window.location.reload(false)
		}
		else {
			dispatch(
				resetAllInfomation({})
			  )
			history.push({
				pathname: `/student/${studentID}`,
			})
		}
	}
	useEffect(() => {
		if (_.isEmpty(info_maintenance_student)) {
			dispatch(getInfoMaintenance());
		}
	  }, [dispatch, info_maintenance_student]);
	return (
		<Layout>
			<WrapHeader>
				<Description className={location.pathname.includes('/student/curriculum') && location.pathname.includes('/create') ? 'reset-form' : ''} onClick={handleResetForm}>
				{t("high_school_will")}
				</Description>
				{SHOW_NOTI[info_maintenance_student?.is_show_message] === 'show' && !location.pathname.includes('/maintenance') &&
					<NotiMaintenance>
						<WarningOutlined  style={{ fontSize: '20px', color: '#ff8400' }}/>
						{info_maintenance_student?.reason_maintenance || ''}
					</NotiMaintenance>
				}
			</WrapHeader>
		</Layout>
	);
}

export default Header;
