import styled from 'styled-components';
import { Layout, Row } from 'antd';

export const Description = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 2rem;  
  color: #FFFFFF;
  padding: 0 2rem
`;

export const WrapFooter = styled(Layout.Footer)`
  background: #7D0006;
  height: 15rem;
  text-align: "center";
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

export const WrapRow = styled(Row)`
  flex-direction: column;
  text-align: center;
`;

export const ImageLogo = styled.img`
  width: 23rem;
  height: 3.9rem;
`;