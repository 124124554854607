import React, { useEffect, useState } from "react";
import {
  BarsOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TableOutlined,
  ToolOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import {
  HeaderTitle,
  Logout,
  LogoutAction,
  WrapAdmin,
} from "./styled";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "Store/Actions";
import LoadingModal from "Pages/LoadingModal";
import SubMenu from "antd/es/menu/SubMenu";
import { STORAGE, removeLocalStorage } from "Utils/storage";
const {  getGroupSchool, resetState } = actions;
const { Header, Sider, Content } = Layout;

const AdminLayout = ({ children }) => {
  const { loading, dataGroupSchool } = useSelector((state) => state.admin);
   const [collapsed, setCollapsed] = useState(false);
   const [title, setTitle] = useState();
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingImport, setLoadingImport] = useState(false);


  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const getMenuItemKey = (path) => {
    let key = menuItemsData[0]?.key;
    for (const item of menuItemsData) {
      if (item.link === path) {
        key = item.key;
      }
      if (!item.link) {
        for (const child of item.children) {
          if (child.link === path) {
            key = child.key;
            break;
          }
        }
      }
    }
    return key;
  };
  const path = location.pathname;
  useEffect(() => {
    setSelectedMenuItems([getMenuItemKey(path)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  const menuItemsData = [
    {
      key:'10',
      icon: <UploadOutlined />,
      title: "インポート",
      className: "all_course",
      link: "/admin/import_flow",
    },
    {
      key:'sub1',
      icon: <BarsOutlined />,
      title: "講座関連情報",
      className: "all_course",
      children: [
        {
          key: "1",
          icon: <TableOutlined />,
          title: "有効な講座一覧",
          link: "/admin/course_valid",
        },
        {
          key: "2",
          icon: <TableOutlined />,
          title: "無効な講座一覧",
          link: "/admin/course_import_invalid",
        },
        {
          key: "3",
          icon: <TableOutlined />,
          title: "12パターン統合",
          link: "/admin/12_group",
        },
      ],
    },
    {
      key:'sub2',
      icon: <BarsOutlined />,
      title: "講座セット",
      className: "all_course",
      children: [
        {
          key: "4",
          icon: <TableOutlined />,
          title: "有効な講座セット",
          link: "/admin/course_dependencies_valid",
        },
        {
          key: "5",
          icon: <TableOutlined />,
          title: "無効な講座セット",
          link: "/admin/course_dependencies_invalid",
        },
      ],
    },
    {
      key:'sub3',
      icon: <BarsOutlined />,
      title: "グループ関連情報",
      className: "all_course",
      children: [
        {
          key: "6",
          icon: <TableOutlined />,
          title: "有効な大学",
          link: "/admin/school_group_valid",
        },
        {
          key: "7",
          icon: <TableOutlined />,
          title: "無効な大学",
          link: "/admin/school_group_import_invalid",
        },
        {
          key: "8",
          icon: <TableOutlined />,
          title: "科目別配点用",
          link: "/admin/seihoku_import",
        },
      ],
    },
    {
      key:'12',
      icon: <BarsOutlined />,
      title: "受講予定講座管理",
      className: "all_course",
      link: "/admin/manager_schedules",
    },
    {
      key:'13',
      icon: <ToolOutlined />,
      title: "メンテナンス設定",
      className: "all_course",
      link: "/admin/maintenance",
    },
  ];
  useEffect(() => {
    if (path === '/admin/12_group' || path === '/admin/school_group_valid' || path === '/admin/school_group_import_invalid') {
      !dataGroupSchool.length && dispatch(getGroupSchool((action, res) => { }));
    }
    // dispatch(getVersion((action, res) => {}));
  },[dataGroupSchool.length, dispatch, path])

  const generateMenuItems = (items) =>
  items.map((item) => {
    if (item.children) {
      return (
        <SubMenu key={item.key} icon={item.icon} title={item.title}>
          {generateMenuItems(item.children)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        {item.link ? <Link to={item.link}>{item.title}</Link> : item.title}
      </Menu.Item>
    );
  });
  const handleLogout = () => {
    removeLocalStorage(STORAGE.ADMIN_TOKEN)
    history.push({
      pathname: `/admin/login`,
    });
    dispatch(resetState((action, res) => { }));
  };

  const handleCancel = () => {
    setLoadingImport(false)
  }

  const showHeader = (step) => {
    switch (step) {
      case '/admin/import_flow':
        setTitle("インポート")
        break;
      case '/admin/course_valid':
        setTitle("有効な講座一覧")
        break;
      case '/admin/course_import_invalid':
        setTitle("無効な講座一覧")
        break;
      case '/admin/12_group':
        setTitle("12パターン統合")
        break;
      case '/admin/course_dependencies_valid':
        setTitle("有効な講座セット")
        break;
      case '/admin/course_dependencies_invalid':
        setTitle("無効な講座セット")
        break;
      case '/admin/school_group_valid':
        setTitle("有効な大学")
        break;
      case '/admin/school_group_import_invalid':
        setTitle("無効な大学")
        break;
      case '/admin/seihoku_import':
        setTitle("科目別配点用")
        break;
      case '/admin/delete_scheduled_courses':
        setTitle("受講予定講座削除")
        break;
      case '/admin/manager_schedules':
        setTitle("受講予定講座管理")
        break;
      case '/admin/maintenance':
        setTitle("メンテナンス設定")
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    showHeader(path)
  }, [path])

  return (
    <WrapAdmin>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div>
            <div className="logo">
              <Button
                className="resize-sidebar"
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <Menu theme="dark" mode="inline"
              onSelect={({ selectedKeys }) =>
                setSelectedMenuItems(selectedKeys)
              }
              defaultOpenKeys={['sub1', 'sub2', 'sub3']} 
              selectedKeys={selectedMenuItems}
            >
              {generateMenuItems(menuItemsData)}
            </Menu>
          </div>
          <Logout className="logout-tab">
            <LogoutAction className="logout" onClick={handleLogout}>
              <LogoutOutlined/> ログアウト
            </LogoutAction>
          </Logout>
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            {/* {path === '/admin/12_group' ? (
              <SelectItem>
                <Form.Item label={<WrapperLabel>グループ関連情報</WrapperLabel>}>
                  <Select
                    className="select__group-school"
                    name="version"
                    showSearch
                    options={dataGroupSchool}
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    value={selectGroup}
                    onChange={selectSchool}
                  />
                </Form.Item>
              </SelectItem>*/}
              <HeaderTitle>
                <span>
                  {title}
                </span>
              </HeaderTitle>
          </Header>
          <Content>{children}</Content>
        </Layout>
      </Layout>
      {loading && loadingImport && <LoadingModal textLoading={""} cancelEvent={handleCancel}/>}
    </WrapAdmin>
  );
};

export default AdminLayout;
